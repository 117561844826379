import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './assets/ghs-logo-white.svg';

function App() {
  // State to hold categorized PDF links
  const [pdfLinks, setPdfLinks] = useState({});
  // State to track selected category
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchPdfLinks = async () => {
      console.log("Attempting to fetch PDF links...");
      try {
        const response = await fetch('https://f46wmnbw7f.execute-api.eu-north-1.amazonaws.com/default/GetShowOfficeFiles');
        if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
        const filenames = await response.json();
        
        // Initialize categories based on filenames that end with "/"
        const categories = filenames.filter(filename => filename.endsWith("/")).reduce((acc, cur) => {
          acc[cur.replace('/', '')] = []; // Use category name without trailing slash as key
          return acc;
        }, {});

        // Populate categories with their respective files, excluding directory names
        filenames.forEach(filename => {
          // Skip directory names
          if (filename.endsWith("/")) return;

          // Find which category the file belongs to
          const category = Object.keys(categories).find(cat => filename.startsWith(`${cat}/`));
          if (category) {
            categories[category].push({
              name: filename.replace(`${category}/`, '').replace('.pdf', ''), // Remove the category prefix and '.pdf'
              url: `https://d354viqc2sz7pg.cloudfront.net/${filename}`,
            });
          }
        });

        setPdfLinks(categories);
      } catch (error) {
        console.error("Failed to fetch PDF links:", error);
      }
    };

    fetchPdfLinks();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Gothenburg Horse Show 2024" className="logo" />
        <h1 className="headline">Digital Showoffice</h1>
      </header>
      <main className="App-content">
        <div className="categories-container">
          {Object.keys(pdfLinks).map(category => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className="pdf-link-button category-button" // Added class for category buttons
            >
              {category}
            </button>
          ))}
        </div>
        <div className="pdf-links-container">
          {selectedCategory && pdfLinks[selectedCategory].map((pdf, index) => (
            <button
              key={index}
              onClick={() => window.open(pdf.url, "_blank")}
              className="pdf-link-button"
            >
              {pdf.name}
            </button>
          ))}
        </div>
      </main>
    </div>
  );
}

export default App;
